// ============================
//  Libraries Imports
// ============================
import styled from "styled-components";
// ============================
//  Commons Components
// ============================

// ============================
//  Styled Components
// ============================
const StyledButton = styled.button`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  color: ${(props) => props.color};
  text-align: center;
  line-height: 1;
  border: none;
  transition: box-shadow 0.15s ease-in-out;
  font-size: 20px;
  background-color: ${(props) => props.backgroundColor};
  @media (max-height: 400px), screen and (max-width: 1200px) {
    font-size: 20px;
    gap: 12px;
  }
`;

StyledButton.defaultProps = {
  width: "100%",
  height: "45px",
  //backgroundColor: "rgb(139 92 246 / 800)",
  backgroundColor: " #31B9A9",
  theme: "",
  color: "rgb(236 235 255",
};

// ============================
//  Principal Component
// ============================
const CommonButton = ({ children, ...props }) => {
  return (
    <StyledButton
      className="bg-evowy text-white font-medium rounded-lg tracking-wide hover:scale-105 px-5 py-2 text-lg transition-all duration-300"
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export default CommonButton;
