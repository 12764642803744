import getUrl from "../helpers/config";

const url = getUrl();

let status = 0;
let data;

const fetchUserDataByToken = async (uri, method = "GET") => {
  let dataFetched;
  await fetch(`${url}/${uri}`, {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then((response) => {
      status = response.status;
      if (status !== 500) {
        return response.json();
      }
    })
    .then((recurso) => {
      if (recurso) {
        data = recurso;
      }
    })
    .catch(function (error) {});

  switch (status) {
    case 200:
    case 201:
      dataFetched = {
        code: status,
        flag: true,
        result: await data,
      };
      break;
    default:
      dataFetched = {
        code: status,
        flag: false,
        result: await data,
      };
      break;
  }

  return dataFetched;
};

export default fetchUserDataByToken;
