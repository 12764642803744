import { useField } from "formik";
import React from "react";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import InputGroup from "react-bootstrap/InputGroup";
import styled, { css } from "styled-components";

const StyledErrorMessage = styled(FormControl.Feedback)`
  text-align: center;
  color: red;
  height: 0;
  font-weight: 600;
`;

const StyledControl = styled(FormGroup)``;

const StyledLabel = styled(FormLabel)`
  float: left;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #323f4b;
  margin-left: 1rem;
  letter-spacing: 1px;
`;

const StyledInput = styled.input`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  max-width: 100%;
  padding-left: 10px;
  font-size: 18px;
  /* Props for color */
  border: 2px solid;
  ${(props) =>
    props.theme &&
    css`
      border-color: ${(props) => props.theme};
    `}
  ${(props) =>
    props.background &&
    css`
      background-color: ${(props) => props.background} !important;
    `}
`;

StyledInput.defaultProps = {
  width: "100%",
  height: "45px",
  theme: "",
};

function CommonInput({ label, type, background, ...props }) {
  const [field, meta] = useField(props);

  if (type === "checkbox") {
    return (
      <StyledControl className="w-full">
        {" "}
        <InputGroup hasValidation>
          <div className="flex flex-row items-center">
            <StyledInput
              type={type}
              {...field}
              {...props}
              className="focus:outline-none mr-3"
              isInvalid={meta.touched && meta.error}
            />
            <StyledLabel>{label}</StyledLabel>
          </div>
          {meta.touched && meta.error ? (
            <StyledErrorMessage
              type="invalid"
              className="text-red-600 text-md mt-1"
            >
              {meta.error}
            </StyledErrorMessage>
          ) : null}
        </InputGroup>
      </StyledControl>
    );
  } else {
    return (
      <StyledControl className="w-full">
        {" "}
        <StyledLabel>{label}</StyledLabel>
        <InputGroup hasValidation>
          <StyledInput
            type={type}
            {...field}
            {...props}
            className={`focus:outline-none w-full h-[45px] pl-3 border-2 border-evowy ${
              background ? background : "bg-silver"
            }`}
            isInvalid={meta.touched && meta.error}
          />
          {meta.touched && meta.error ? (
            <StyledErrorMessage
              type="invalid"
              className="text-red-600 text-md mt-1"
            >
              {meta.error}
            </StyledErrorMessage>
          ) : null}
        </InputGroup>
      </StyledControl>
    );
  }
}

export default CommonInput;
