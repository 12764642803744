import getUrl from "../../helpers/config";
const url = getUrl();

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const sendRepassByEmail = async (email, method = "GET") => {
  let dataFetched;
  if (validateEmail(email)) {
    const fetching = await fetch(`${url}/rePass/${email}`, {
      method,
    });

    switch (fetching.status) {
      case 200:
        dataFetched = {
          flag: true,
          result: await fetching.json(),
        };
        break;
      default:
        dataFetched = {
          flag: false,
          result: { message: "internal.error" },
        };
        break;
    }
    return dataFetched;
  } else {
    return { flag: false, result: { message: "valid.email.check" } };
  }
};

export default sendRepassByEmail;
