import { Form, Formik } from "formik";
import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import * as Yup from "yup";
import Person from "../../assets/images/Elon_space_2.svg";
import EvowyLogo from "../../assets/images/evowy-negro.svg";
import LandingNoPerson from "../../assets/images/Landing_no_person_2.svg";
import { userContext } from "../../contexts/userContext";
import loginUser from "../../services/Login/loginUser";
import sendRepassByEmail from "../../services/RePass/sendRepassByEmail";
import AllRigthsReserved from "../Common/AllRigthsReserved";
import CommonButton from "../Common/CommonButton";
import CommonInput from "../Common/CommonInput";
import LoadingSwal from "../Common/Swal/LoadingSwal";
import StyledSwalSameLine from "../Common/Swal/StyledSwalSplitLine";
import "./Register.css";

const backgroundColor = "rgb(236 235 255)";
// const themeBorder = "rgb(139 92 246 / 800)";
const themeBorder = "#31B9A9";

const StyledInputLabel = styled.span`
  position: relative;
  top: 10px;
  left: 20px;
  width: fit-content;
  background: rgb(236 235 255);
  padding: 0 5px;
  color: ${themeBorder};
`;
// const StyledImage = styled.img`
//   position: absolute;
//   width: 35%;
//   bottom: -20px;
//   left: 2%;
//   z-index: 0;
// `;

// const StyledImage2 = styled.img`
//   position: absolute;
//   width: 30%;
//   right: 2%;
//   z-index: 0;
// `;

function Login({ setToken }) {
  const intl = useIntl();
  const context = useContext(userContext);
  const navigate = useNavigate();

  const schema = Yup.object().shape({
    password: Yup.string()
      .min(5, intl.formatMessage({ id: "form.minimun.check" }, { size: 5 }))
      .max(255, intl.formatMessage({ id: "form.maximum.check" }, { size: 255 }))
      .required(intl.formatMessage({ id: "form.required.check" })),
    email: Yup.string()
      .email(intl.formatMessage({ id: "valid.email.check" }))
      .max(255, intl.formatMessage({ id: "form.maximum.check" }, { size: 255 }))
      .required(intl.formatMessage({ id: "form.required.check" })),
  });

  const handleChangePasswd = () => {
    StyledSwalSameLine.fire({
      title: intl.formatMessage({ id: "login.repass.popup.title" }),
      text: intl.formatMessage({ id: "login.repass.popup.text" }),
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCloseButton: true,
      confirmButtonText: intl.formatMessage({ id: "submit" }),
      showLoaderOnConfirm: true,
      preConfirm: async (email) => {
        let datareturn = await sendRepassByEmail(email);
        if (!datareturn.flag) {
          Swal.showValidationMessage(
            intl.formatMessage({ id: datareturn.result.message })
          );
        } else {
          StyledSwalSameLine.fire({
            title: intl.formatMessage({ id: "login.repass.popup.ok.title" }),
            text: intl.formatMessage({ id: "login.repass.popup.ok.text" }),
            icon: "success",

            showCloseButton: true,
          });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const handleSubmit = async (values) => {
    LoadingSwal(intl);
    let fetch = await loginUser(values, "login").catch((err) => {
      StyledSwalSameLine.fire({
        title: intl.formatMessage({ id: "fetch.error" }),
        text: intl.formatMessage({ id: "fetch.error.message" }),
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: intl.formatMessage({ id: "try.again" }),
      });
    });
    if (fetch.flag === true) {
      setToken(fetch.result);
      context.contextValue.setLanguage(fetch.result.locale);
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.delete("id_token");
      const paramsString = queryParams.toString();
      var parameters;

      context.contextValue.setLanguage(fetch.result.locale);
      context.contextValue.setUser({
        user_id: fetch.result.user_id,
        firstname: fetch.result.firstname,
        locale: fetch.result.locale,
      });
      if (paramsString !== "") {
        parameters = "?" + paramsString;
      } else {
        parameters = "";
      }
      navigate("/car/list");

      Swal.close();
    } else {
      StyledSwalSameLine.fire({
        title: intl.formatMessage({ id: "fetch.error" }),
        text: intl.formatMessage({ id: fetch.result.message }),
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: intl.formatMessage({ id: "try.again" }),
      });
    }
  };

  return (
    <div className="w-screen h-full md:max-h-full md:max-w-screen min-w-screen min-h-screen bg-gray-800 overflow-hidden flex items-center">
      <div className="h-screen lg:flex top-0 left-0 w-screen overflow-hidden hidden fixed">
        <img
          className="animate-bounce-slow styledImage"
          src={Person}
          alt="Elon pointing form"
        ></img>
      </div>
      <div className="absolute h-screen lg:flex top-0 left-0 w-screen overflow-hidden items-center hidden">
        <img
          className="styledImage2"
          src={LandingNoPerson}
          alt="Manage your car data"
        ></img>
      </div>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={schema}
        onSubmit={(values) => handleSubmit(values)}
      >
        <Form>
          <div className="flex justify-center items-center w-screen md:h-full bg-gray-800 flex-col">
            <div className="mt-5 flex flex-col gap-4 bg-silver md:py-8 md:px-20 px-8 py-6 z-10 max-w-full w-11/12 sm:w-8/12 md:w-5/12 md:min-w-[500px] xl:w-4/12">
              <div className="text-xl md:text-2xl font-semibold text-evowy flex justify-center">
                <img
                  className="px-5 py-2 max-w-[200px]"
                  src={EvowyLogo}
                  alt="logo"
                ></img>
              </div>
              <h1 className="text-center text-xl font-semibold tracking-wider text-evowy">
                <FormattedMessage
                  id={"sesion.login"}
                  defaultMessage={"Login"}
                ></FormattedMessage>
              </h1>
              <div className="flex justify-start flex-col w-full max-w-full">
                <StyledInputLabel className="styledSpan">
                  <FormattedMessage
                    id="email"
                    defaultMessage={"Email"}
                  ></FormattedMessage>
                </StyledInputLabel>
                <CommonInput
                  theme={themeBorder}
                  type="text"
                  name="email"
                  placeholder="e.g elon@tesla.com"
                ></CommonInput>
              </div>
              <div className="flex justify-start flex-col w-full max-w-full">
                <StyledInputLabel className="styledSpan">
                  <FormattedMessage
                    id="password"
                    defaultMessage={"Password"}
                  ></FormattedMessage>
                </StyledInputLabel>
                <CommonInput
                  theme={themeBorder}
                  type="password"
                  name="password"
                  placeholder="**********"
                ></CommonInput>
                <p
                  className="mt-10 underline text-evowy cursor-pointer"
                  onClick={handleChangePasswd}
                >
                  <FormattedMessage id="login.repass"></FormattedMessage>
                </p>
              </div>

              <CommonButton type="submit">
                <FormattedMessage
                  id="login"
                  defaultMessage={"Login"}
                ></FormattedMessage>
              </CommonButton>

              <p className="text-center">
                <FormattedMessage
                  id={"no.account.yet"}
                  defaultMessage={"Still not an EVOwier?"}
                ></FormattedMessage>{" "}
                <br></br>
                <FormattedMessage
                  id={"dont.worry"}
                  defaultMessage={"Don't worry, join the family "}
                ></FormattedMessage>{" "}
                <b
                  onClick={() => navigate("/register")}
                  className="cursor-pointer text-evowy underline"
                >
                  <FormattedMessage
                    id={"here"}
                    defaultMessage={"here"}
                  ></FormattedMessage>
                </b>
              </p>
            </div>
            <AllRigthsReserved className="mb-10" />
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default Login;
