import Swal from "sweetalert2";
import "./SwalStyles.css";

function LoadingSwal(intl) {
  const LoadingSwal = Swal.mixin({
    customClass: {
      popup: "swal-popup-styled",
      confirmButton: "swal-confirm-button swal-buttons-animation",
      cancelButton: "swal-cancel-button swal-buttons-animation",
      title: "swal-title-styled-small",
      closeButton: "swal-closebutton-styled",
      actions: "swal-action", // row ? 'swal-action' : ''
    },
    buttonsStyling: false,
  });

  LoadingSwal.fire({
    title: intl.formatMessage({ id: "swal.loading.text" }),
    onAfterClose() {
      Swal.hideLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    showConfirmButton: false,
    showCancelButton: false,
  });
  LoadingSwal.showLoading();
}

export default LoadingSwal;
