// ============================
//  Ohers Imports
// ============================
import getUrl from "../../helpers/config";

const url = getUrl();

const leadRegister = async (data, uri, method = "POST") => {
  let dataFetched;
  data["locale"] = localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : "es-ES";
  const fetching = await fetch(`${url}/${uri}`, {
    method,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  switch (fetching.status) {
    case 201:
      dataFetched = {
        flag: true,
        result: await fetching.json(),
      };
      break;
    case 500:
      dataFetched = {
        flag: false,
        result: await fetching.json(),
      };
      break;
    case 401:
      dataFetched = {
        flag: false,
        result: await fetching.json(),
      };
      break;
    default:
      dataFetched = {
        flag: false,
        result: await fetching.json(),
      };
      break;
  }

  return dataFetched;
};

export default leadRegister;
