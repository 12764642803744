import React from "react";
import { motion } from "framer-motion";

function Loader() {
  return (
    <motion.div className="w-screen h-screen bg-white flex justify-center items-center">
      <motion.div className="h-64 w-52 rounded-lg border-4 border-gray-500 grid grid-rows-3 gap-3 p-3">
        <motion.div
          animate={{
            scale: [0, 0, 0, 1, 0, 0, 0]
          }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            times: [0.33, 0.33, 0.33, 0.33, 0.33, 0.33],
            repeat: Infinity,
            repeatDelay: 1,
          }}
          className="bg-evowy rounded-md"
        >
          <div className="bg-evowy rounded-md blur-sm w-full h-full" />
        </motion.div>
        <motion.div
          animate={{
            scale: [0, 0, 1, 1, 1, 0, 0]
          }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            times: [],
            repeat: Infinity,
            repeatDelay: 1,
          }}
          className="bg-evowy rounded-md"
        >
          <div className="bg-evowy rounded-md blur-sm w-full h-full" />
        </motion.div>
        <motion.div
          animate={{
            scale: [0, 1, 1, 1, 1, 1, 0]
          }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            times: [0.33, 0.33, 0.33, 0.33, 0.33, 0.33],
            repeat: Infinity,
            repeatDelay: 1,
          }}
          className="bg-evowy rounded-md"
        >
          <div className="bg-evowy rounded-md blur-sm w-full h-full" />
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default Loader;
