import Swal from "sweetalert2";
import "./SwalStyles.css";

const StyledSwalSplitLine = Swal.mixin({
  customClass: {
    popup: "swal-popup-styled",
    confirmButton: "swal-confirm-button swal-buttons-animation",
    cancelButton: "swal-cancel-button swal-buttons-animation",
    title: "swal-title-styled",
    closeButton: "swal-closebutton-styled",
  },
  buttonsStyling: false,
});
export default StyledSwalSplitLine;
