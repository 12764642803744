import React, { lazy, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import StyledSwalSingle from "./components/Common/Swal/StyledSwalSplitLine";
import fetchUserDataByToken from "./contexts/fetchUserDataByToken";
import { userContext } from "./contexts/userContext";
import useToken from "./hooks/useToken";
import PrivateRoutes from "./PrivateRoutes";
import loginUser from "./services/Login/loginUser";

const Login = lazy(() => import("./components/Auth/Login"));
const Repass = lazy(() => import("./components/Auth/Repass"));
const Referal = lazy(() => import("./components/Referal/Referal"));
const Register = lazy(() => import("./components/Auth/Register"));
const SmartCarAuth = lazy(() => import("./components/Auth/SmartCarAuth"));
const CarDetail = lazy(() => import("./components/CarDetail/CarDetail"));
const MainPanel = lazy(() => import("./components/Control_Panel/MainPanel"));
const ReportsList = lazy(() => import("./components/Reports/ReportsList"));
const SetPassword = lazy(() => import("./components/Auth/SetPassword"));
const ChangePassword = lazy(() => import("./components/Auth/ChangePassword"));
const Settings = lazy(() => import("./components/Other/Settings"));
const ConnectedCarsList = lazy(() =>
  import("./components/Cars/ConnectedCarsList")
);

const Error404 = lazy(() => import("./components/Common/Errors/Error404"));

function App() {
  const { setToken } = useToken();
  const intl = useIntl();
  const [getToken, setLocalToken] = useState(localStorage.getItem("token"));
  const context = useContext(userContext);

  useEffect(() => {
    async function cargarUsuario() {
      // Si viene por URL el Token, es decir, desde Email
      const queryParams = new URLSearchParams(window.location.search);
      const id_token = queryParams.get("id_token");
      queryParams.delete("id_token");
      queryParams.delete("src");
      if (window.location.pathname !== "/repass") {
        queryParams.delete("id_token");
      }
      const utm_campaign = queryParams.get("utm_campaign");
      queryParams.delete("utm_campaign");
      const utm_medium = queryParams.get("utm_medium");
      queryParams.delete("utm_medium");
      const utm_source = queryParams.get("utm_source");
      queryParams.delete("utm_source");

      if (utm_campaign) {
        localStorage.setItem("utm_campaign", utm_campaign);
      }
      if (utm_medium) {
        localStorage.setItem("utm_medium", utm_medium);
      }
      if (utm_source) {
        localStorage.setItem("utm_source", utm_source);
      }

      const locale = queryParams.get("locale");
      queryParams.delete("locale");

      const paramsString = queryParams.toString();
      var parameters = "";
      if (paramsString !== "") {
        parameters = "?" + paramsString;
      }

      if (locale !== null) {
        context.contextValue.setLanguage(locale);
      }

      if (id_token !== null && window.location.pathname !== "/repass") {
        let fetch = await loginUser({ idToken: id_token }, "login");

        if (fetch.flag === true) {
          context.contextValue.setLanguage(fetch.result.locale);
          context.contextValue.setUser({
            user_id: fetch.result.user_id,
            firstname: fetch.result.firstname,
            locale: fetch.result.locale,
          });
          localStorage.setItem("token", fetch.result.access_token);
          setLocalToken(fetch.result.access_token);
          window.location.assign(window.location.pathname + parameters);
        }
      }
      if (localStorage.getItem("token")) {
        let response = await fetchUserDataByToken(`user/myDataByToken`);
        if (response.flag === true) {
          if (response.result.user) {
            context.contextValue.setUser({
              user_id: response.result.user.id,
              firstname: response.result.user.firstname,
              email: response.result.user.email,
              country: response.result.user.country,
              locale: response.result.user.locale,
            });
          }
        } else {
          StyledSwalSingle.fire({
            title: intl.formatMessage({ "id": "login" }),
              //context.contextValue.lang === "es-ES" ? "Logueate" : "Login!",
            text: intl.formatMessage({ "id": "reauthenticate" }),
            /*
              context.contextValue.lang === "es-ES"
                ? "Necesitamos que te re-autentiques"
                : "We need you to re-authenticate",
            */
            icon: "error",
            confirmButtonColor: "#31B9A9",
            confirmButtonText: intl.formatMessage({ "id": "go.login" }),
            /*"es-ES" ? "Ir a Login" : "Go to Login",*/
          }).then(() => {
            localStorage.removeItem("token");
            window.location.href = "/login";
          });
        }
      }
    }
    cargarUsuario();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Error404 />} />]
        <Route element={<PrivateRoutes setToken={setToken} />}>
          <Route path="/" element={<ConnectedCarsList />} />
          <Route path="/car/auth" element={<SmartCarAuth />} />
          <Route path="/car/list" element={<ConnectedCarsList />} />
          <Route path="/car/detail/:car" element={<CarDetail />} />
          <Route path="/referals" element={<Referal />} />
          <Route path="/setpasswd" element={<SetPassword />} />
          <Route path="/changepasswd" element={<ChangePassword />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/reports" element={<ReportsList />} />

          <Route path="/report/:car/:year/:month" element={<MainPanel />} />
        </Route>
        {/* <Route element={<AuthRoutes />}> */}
        <Route path="/login" element={<Login setToken={setToken} />} />
        <Route path="/repass" element={<Repass />} />
        <Route path="/register" element={<Register setToken={setToken} />} />
        {/* </Route> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
