import React, { useCallback, useMemo, useState } from "react";
import { IntlProvider } from "react-intl";
import Swal from "sweetalert2";
import EnglishMessages from "../lang/en-UK.json";
import SpanishMessages from "../lang/es-ES.json";
import PortugalMessages from "../lang/pt-PT.json";

const userContext = React.createContext();
const UserProvider = ({ children }) => {
  let localeByDefault;
  let messagesByDefault;

  const lang = localStorage.getItem("lang") || "es-ES";

  if (lang) {
    localeByDefault = lang;

    if (lang === "es-ES") {
      messagesByDefault = SpanishMessages;
    } else if (lang === "en-UK") {
      messagesByDefault = EnglishMessages;
    } else if (lang === "pt-PT") {
      messagesByDefault = PortugalMessages;
    } else {
      localeByDefault = "es-ES";
      messagesByDefault = SpanishMessages;
    }
  }

  const [messages, setMessagges] = useState(messagesByDefault);
  const [locale, setLocale] = useState(localeByDefault);

  const setLanguage = (language) => {
    switch (language) {
      case "es-ES":
        setMessagges(SpanishMessages);
        setLocale("es-ES");
        localStorage.setItem("lang", "es-ES");
        break;
      case "en-UK":
        setMessagges(EnglishMessages);
        setLocale("en-UK");
        localStorage.setItem("lang", "en-UK");
        break;
      case "pt-PT":
        setMessagges(PortugalMessages);
        setLocale("pt-PT");
        localStorage.setItem("lang", "pt-PT");
        break;
      default:
        setMessagges(SpanishMessages);
        setLocale("es-ES");
        localStorage.setItem("lang", "es-ES");
        break;
    }
  };
  const [currentUser, setCurrentUser] = useState({
    user_id: null,
    firstname: null,
    locale: null,
  });

  const setUser = (user) => {
    setCurrentUser(user);
  };

  var [commonValues, setCommonValues] = useState({
    vehicle_id: null,
    aux: null,
  });

  const setCValues = (values) => {
    setCommonValues(values);
  };


  const logout = useCallback(() => {
    setUser(null);
  }, []);

  const contextValue = useMemo(
    () => ({
      currentUser,
      logout,
      lang,
      setLanguage,
      setUser,
      commonValues,
      setCValues
    }),
    [currentUser, logout, lang, commonValues]
  );

  return (
    <userContext.Provider
      value={{
        contextValue,
      }}
    >
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </userContext.Provider>
  );
};

export { UserProvider, userContext };
