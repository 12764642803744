// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from "react";
import { Outlet } from "react-router-dom";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";

const PrivateRoutes = ({ setToken }) => {
  // Add your own authentication on the below line.
  const isLoggedIn = localStorage.getItem("token");

  //const isLoggedIn = localStorage.getItem("token");
  return isLoggedIn ? (
    <Outlet></Outlet>
  ) : window.location.pathname === "/register" ? (
    <Register setToken={setToken} />
  ) : (
    <Login setToken={setToken}></Login>
  );
  // return <Outlet></Outlet>;
};

export default PrivateRoutes;
