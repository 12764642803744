import { Form, Formik } from "formik";
import React, { useContext, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import * as Yup from "yup";
import Person from "../../assets/images/Elon_space_2.svg";
import LandingNoPerson from "../../assets/images/Landing_no_person_2.svg";
import { userContext } from "../../contexts/userContext";
import leadRegister from "../../services/LeadCatch/leadRegister";
import AllRigthsReserved from "../Common/AllRigthsReserved";
import CommonButton from "../Common/CommonButton";
import CommonInput from "../Common/CommonInput";
import LoadingSwal from "../Common/Swal/LoadingSwal";
import StyledSwalSameLine from "../Common/Swal/StyledSwalSplitLine";
import "./Register.css";

const backgroundColor = "rgb(236 235 255)";
// const themeBorder = "rgb(139 92 246 / 800)";
const themeBorder = "#31B9A9";

const StyledInputLabel = styled.span`
  position: relative;
  top: 10px;
  left: 20px;
  width: fit-content;
  background: rgb(236 235 255);
  padding: 0 5px;
  color: ${themeBorder};
`;

let utm_campaign = localStorage.getItem("utm_campaign");
let utm_medium = localStorage.getItem("utm_medium");
let utm_source = localStorage.getItem("utm_source");
function Register({ setToken }) {
  const intl = useIntl();
  const context = useContext(userContext);
  let navigate = useNavigate();
  let locale = "";

  useEffect(() => {}, []);

  const schema = Yup.object().shape({
    firstname: Yup.string()
      .max(255, intl.formatMessage({ id: "form.maximum.check" }, { size: 255 }))
      .required(intl.formatMessage({ id: "form.required.check" })),
    email: Yup.string()
      .email(intl.formatMessage({ id: "valid.email.check" }))
      .max(255, intl.formatMessage({ id: "form.maximum.check" }, { size: 255 }))
      .required(intl.formatMessage({ id: "form.required.check" })),
    country: Yup.string().required(
      intl.formatMessage({ id: "form.required.check" })
    ),
    politics: Yup.boolean().oneOf(
      [true],
      intl.formatMessage({ id: "form.required.check" })
    ),
  });

  const handleSubmit = async (values) => {
    if (utm_campaign) {
      values.utm_campaign = utm_campaign;
    }
    if (utm_medium) {
      values.utm_medium = utm_medium;
    }
    if (utm_source) {
      values.utm_source = utm_source;
    }

    LoadingSwal(intl);
    let fetch = await leadRegister(values, "lead/register").catch((err) => {
      StyledSwalSameLine.fire({
        title: intl.formatMessage({ id: "fetch.error" }),
        text: intl.formatMessage({ id: "fetch.error.message" }),
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: intl.formatMessage({ id: "try.again" }),
      });
    });
    if (fetch.flag === true) {
      setToken(fetch.result);
      locale = fetch.result.locale;
      context.contextValue.setLanguage(fetch.result.locale);
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.delete("id_token");
      context.contextValue.setLanguage(fetch.result.locale);

      context.contextValue.setUser({
        user_id: fetch.result.user_id,
        firstname: fetch.result.firstname,
        locale: fetch.result.locale,
      });

      Swal.close();
      StyledSwalSameLine.fire({
        title: intl.formatMessage({ id: "created.user" }),
        text: intl.formatMessage({ id: "created.user.text" }),
        icon: "success",
        confirmButtonColor: "#3085d6",
      }).then(function () {
        navigate("/car/auth");
      });
    } else {
      StyledSwalSameLine.fire({
        title: intl.formatMessage({ id: "fetch.error" }),
        text: intl.formatMessage({ id: fetch.result.message }),
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: intl.formatMessage({ id: "try.again" }),
      });
    }
  };

  return (
    <div className="w-screen h-full md:max-h-full md:max-w-screen min-w-screen min-h-screen bg-gray-800 overflow-hidden flex items-center">
      <div className="h-screen lg:flex top-0 left-0 w-screen overflow-hidden hidden fixed">
        <img
          className="animate-bounce-slow styledImage"
          src={Person}
          alt="elon pointing form"
        ></img>
      </div>
      <div className="absolute h-screen lg:flex top-0 left-0 w-screen overflow-hidden items-center hidden">
        <img
          className="styledImage2"
          src={LandingNoPerson}
          alt="manage your car data"
        ></img>
      </div>
      <div className="flex justify-center items-center w-screen md:h-full bg-gray-800 flex-col">
        <div className="mt-5 flex flex-col gap-4 bg-silver md:py-8 md:px-20 px-8 py-6 z-10 max-w-full w-11/12 sm:w-8/12 md:w-5/12 md:min-w-[500px] xl:w-4/12">
          <h1 className="text-xl md:text-2xl font-semibold text-evowy text-center">
          <FormattedMessage
              id="temporary.unavailable"
              defaultMessage={"We are improving our platform, we will be back soon."}
            ></FormattedMessage>
          </h1>
        </div>
      </div>
      {/*
      <Formik
        initialValues={{
          firstname: "",
          lastname: "",
          email: "",
          country: "",
          politics: false,
        }}
        validationSchema={schema}
        onSubmit={(values) => handleSubmit(values)}
      >
        <Form>
          <div className="flex justify-center items-center w-screen md:h-full bg-gray-800 flex-col">
            <div className="mt-5 flex flex-col gap-4 bg-silver md:py-8 md:px-20 px-8 py-6 z-10 max-w-full w-11/12 sm:w-8/12 md:w-5/12 md:min-w-[500px] xl:w-4/12">
              <h1 className="text-xl md:text-2xl font-semibold text-evowy text-center">
                <FormattedMessage
                  id="be.an.evowier"
                  defaultMessage={"Be an evowier"}
                ></FormattedMessage>
              </h1>
              <div className="flex justify-start flex-col w-full max-w-full">
                <StyledInputLabel className="styledSpan">
                  <FormattedMessage
                    id="firstname"
                    defaultMessage={"First name"}
                  ></FormattedMessage>
                </StyledInputLabel>
                <CommonInput
                  theme={themeBorder}
                  type="text"
                  name="firstname"
                  placeholder="e.g Elon"
                ></CommonInput>
              </div>
              <div className="flex justify-start flex-col w-full max-w-full">
                <StyledInputLabel className="styledSpan">
                  <FormattedMessage
                    id="lastname"
                    defaultMessage={"Last name"}
                  ></FormattedMessage>
                </StyledInputLabel>
                <CommonInput
                  theme={themeBorder}
                  type="text"
                  name="lastname"
                  placeholder="e.g Musk"
                ></CommonInput>
              </div>
              <div className="flex justify-start flex-col w-full max-w-full">
                <StyledInputLabel className="styledSpan">
                  <FormattedMessage
                    id="email"
                    defaultMessage={"Email"}
                  ></FormattedMessage>
                </StyledInputLabel>
                <CommonInput
                  theme={themeBorder}
                  type="text"
                  name="email"
                  placeholder="e.g elon@tesla.com"
                ></CommonInput>
              </div>
              <div className="flex justify-start flex-col w-full max-w-full">
                <StyledInputLabel className="styledSpan">
                  <FormattedMessage
                    id="country"
                    defaultMessage={"Country"}
                  ></FormattedMessage>
                </StyledInputLabel>
                <CommonInput
                  theme={themeBorder}
                  type="text"
                  name="country"
                  placeholder="e.g United States"
                ></CommonInput>
              </div>
              <div className="flex justify-start w-full max-w-full items-center mt-2">
                <CommonInput
                  type="checkbox"
                  id="politics"
                  name="politics"
                  width={4}
                  height={20}
                  label={
                    <p>
                      <FormattedMessage id="privacy.politics"></FormattedMessage>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                          locale === "en-UK"
                            ? "https://evowy.com/privacy"
                            : "https://evowy.es/privacidad"
                        }
                        className="underline"
                      >
                        <FormattedMessage id="terms"></FormattedMessage>
                      </a>
                    </p>
                  }
                ></CommonInput>
              </div>

              <CommonButton type="submit">
                <FormattedMessage
                  id="submit"
                  defaultMessage={"Submit"}
                ></FormattedMessage>
              </CommonButton>
              <p className="text-center">
                <FormattedMessage id={"register.to.login"}></FormattedMessage>{" "}
                <b
                  onClick={() => navigate("/login")}
                  className="cursor-pointer text-evowy underline"
                >
                  <FormattedMessage
                    id={"here"}
                    defaultMessage={"here"}
                  ></FormattedMessage>
                </b>
              </p>
            </div>
            <AllRigthsReserved className="mb-10" />
          </div>
        </Form>
      </Formik>
      */}
    </div>
  );
}

export default Register;
