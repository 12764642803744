// ============================
//  Library Imports
// ============================
const ENVIROMENT = process.env.REACT_APP_ENVIROMENT

const getUrl = () => {

    let BASE_URL

    switch (ENVIROMENT) {
        case 'local':
            BASE_URL = 'http://127.0.0.1:8000/api'
            break;
        case 'development':
            BASE_URL = 'http://api.evowy.test/api'
            break;
        case 'test':
            BASE_URL = 'https://apidev.evowy.com/api'
            break;
        case 'production':
            BASE_URL = 'https://app-api.evowy.com/api'
            break;
        default:
            break;
    }

    return BASE_URL
}

export default getUrl